<template>
  <el-form ref="form" :model="formData" label-width="80px">
    <el-form-item label="申请人" prop="applyName" :rules="rules">
      <el-input style="width:400px" size="small" v-model="formData.applyName" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="applyMobile" :rules="rules">
      <el-input style="width:280px;margin-right:20px" size="small" v-model="formData.applyMobile" placeholder="请输入"></el-input>
      <el-button type="primary" size="small" @click="getCode">{{ str }}</el-button>
    </el-form-item>
    <el-form-item label="验证码">
      <el-input style="width:400px" size="small" v-model="formData.verifyCode" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="">
      <div class="next-btn">
        <span></span>
        <el-button :disabled="!formData.verifyCode" type="primary" size="small" @click="save">下一步</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { getAccpMobileCodeAPI, checkAccpMobileCodeAPI } from '../api'
import { mapState } from 'vuex'
export default {
  name: 'Step1',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        applyName: '',
        applyMobile: '',
        verifyCode: '',
        applyNo: ''
      },
      str: '获取验证码',
      timer: null
    }
  },
  computed: {
    ...mapState('merchant', ['merchantId'])
  },
  mounted() {},

  methods: {
    // 校验验证码
    async save() {
      const { verifyCode, applyNo } = this.formData
      const data = { verifyCode, applyNo }
      const res = await checkAccpMobileCodeAPI(data)
      if (res) {
        this.$emit('next')
      } else {
        this.$message.error('校验失败')
      }
    },
    // 获取验证码
    getCode() {
      if (this.timer) return
      this.$refs.form.validate(valid => {
        if (valid) {
          // 发送验证码请求
          const { applyName, applyMobile } = this.formData
          const data = { merchantId: this.merchantId, applyName, applyMobile }
          getAccpMobileCodeAPI(data).then(res => {
            this.handleCodeText()
            this.formData.applyNo = res.applyNo
            this.$store.commit('merchant/UPDATE_APPLY_NO', res.applyNo)
          })
        }
      })
    },
    handleCodeText() {
      let sec = 59
      this.str = `${sec}s后重新获取`
      this.timer = setInterval(() => {
        sec = sec - 1
        this.str = `${sec}s后重新获取`
        if (sec <= 0) {
          this.str = '获取验证码'
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    init() {
      Object.assign(this._data.formData, this.$options.data().formData)
      clearInterval(this.timer)
      this.str = '获取验证码'
      this.timer = null
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.next-btn {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  margin-top: 40px;
}
</style>
