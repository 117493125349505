<template>
  <el-form ref="form" :model="formData" :rules="rule" label-width="120px">
    <el-form-item class="card-item" label="身份证照" prop="idCardFrontDocId">
      <div class="card">
        <div class="item">
          <FileUpload :name.sync="frontPath"></FileUpload>
          <span class="tip">（人像面）</span>
        </div>
        <div class="item">
          <FileUpload :name.sync="backPath"></FileUpload>
          <span class="tip">（国徽面）</span>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="身份证号" prop="idCardNo" :rules="rules">
      <el-input style="width:400px" v-model="formData.idCardNo" size="small" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="身份证到期日" prop="idCardExpDate" :rules="rules">
      <el-input style="width:400px" v-model="formData.idCardExpDate" size="small" clearable placeholder="请输入"></el-input>
      <span class="tip"> (格式20231001，长期99991231) </span>
    </el-form-item>
    <el-form-item label="身份证地址" prop="idCardAddress" :rules="rules">
      <el-input style="width:400px" v-model="formData.idCardAddress" size="small" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="银行卡号" prop="bankCardNo" :rules="rules">
      <el-input style="width:400px" v-model="formData.bankCardNo" size="small" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="预留手机号" prop="bankCardNo" :rules="rules">
      <el-input style="width:280px;margin-right:20px" size="small" v-model="formData.bankMobile" placeholder="请输入"></el-input>
      <el-button type="primary" size="small" @click="getCode">{{ str }}</el-button>
    </el-form-item>
    <el-form-item label="验证码" prop="" :rules="rules">
      <el-input style="width:400px" size="small" v-model="formData.verifyCode" placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="">
      <div class="next-btn">
        <span></span>
        <el-button :disabled="!formData.verifyCode || !next" type="primary" size="small" @click="save">下一步</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { accpUploadAPI, accpAccountApplyAPI } from '../api'
import FileUpload from '@/components/file-upload/index.vue'
import { mapState } from 'vuex'
export default {
  name: 'Step2',
  components: { FileUpload },
  data() {
    let checkCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('该项不能为空'))
      } else if (!this.formData.idCardBackDocId) {
        return callback(new Error('该项不能为空'))
      }
      callback()
    }
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      rule: {
        idCardFrontDocId: [{ validator: checkCard, trigger: 'blur' }]
      },
      formData: {
        idCardFrontDocId: '',
        idCardBackDocId: '',
        idCardNo: '',
        idCardExpDate: '',
        idCardAddress: '',
        bankCardNo: '',
        bankMobile: '',
        verifyCode: ''
      },
      frontPath: '',
      backPath: '',
      str: '获取验证码',
      timer: null,
      next: false
    }
  },
  computed: {
    ...mapState('merchant', ['applyNo'])
  },
  watch: {
    frontPath: {
      handler(newV, oldV) {
        const data = {
          applyNo: this.applyNo,
          fileCategory: 1,
          fileKey: newV
        }
        accpUploadAPI(data).then(res => {
          this.formData.idCardFrontDocId = res.accpDocId
        })
      }
    },
    backPath: {
      handler(newV, oldV) {
        const data = {
          applyNo: this.applyNo,
          fileCategory: 2,
          fileKey: newV
        }
        accpUploadAPI(data).then(res => {
          this.formData.idCardBackDocId = res.accpDocId
        })
      }
    }
  },
  mounted() {},

  methods: {
    // 下一步
    save() {
      this.$store.commit('merchant/UPDATE_CODE', this.formData.verifyCode)
      this.$emit('next')
    },
    // 获取验证码
    getCode() {
      if (this.timer) return
      this.$refs.form.validate(valid => {
        if (valid) {
          // 发送验证码请求
          const { idCardFrontDocId, idCardBackDocId, idCardNo, idCardExpDate, idCardAddress, bankCardNo, bankMobile } = this.formData
          const data = { applyNo: this.applyNo, idCardFrontDocId, idCardBackDocId, idCardNo, idCardExpDate, idCardAddress, bankCardNo, bankMobile }
          accpAccountApplyAPI(data).then(res => {
            this.handleCodeText()
            if (res) {
              this.next = true
            } else {
              this.$message.error('开户申请失败')
            }
          })
          // const { applyName, applyMobile } = this.formData
          // const data = { merchantId: this.merchantId, applyName, applyMobile }
          // getAccpMobileCodeAPI(data).then(res => {
          //   this.handleCodeText()
          //   this.formData.applyNo = res.applyNo
          //   this.$store.commit('merchant/UPDATE_APPLY_NO', res.applyNo)
          // })
        }
      })
    },
    handleCodeText() {
      let sec = 59
      this.str = `${sec}s后重新获取`
      this.timer = setInterval(() => {
        sec = sec - 1
        this.str = `${sec}s后重新获取`
        if (sec <= 0) {
          this.str = '获取验证码'
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    init() {
      this.$store.commit('merchant/UPDATE_CODE', '')
      Object.assign(this._data.formData, this.$options.data().formData)
      this.frontPath = ''
      this.backPath = ''
      this.next = false
      clearInterval(this.timer)
      this.str = '获取验证码'
      this.timer = null
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style scoped lang="scss">
.el-form /deep/ {
  .card-item {
    .el-form-item__content {
      line-height: 20px;
    }

    .card {
      display: flex;
      .item {
        margin-right: 40px;
        .tip {
          display: block;
          text-align: center;
          font-size: 12px;
          color: #8c939d;
        }
      }
      .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 75px;
        line-height: 75px;
        text-align: center;
      }
      .avatar {
        width: 120px;
        height: 75px;
        display: block;
      }
    }
  }
  .next-btn {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }
  .tip {
    font-size: 13px;
    margin-left: 20px;
    color: #f56c6c;
  }
}
</style>
