import { http } from '@/http/axios.js'
// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/merchant/page',
    method: 'get',
    params
  })
}
// 状态
export function changeStatusAPI(data) {
  return http({
    url: '/admin/merchant/status',
    method: 'post',
    data
  })
}
// 删除
export function deleteMerchantAPI(id) {
  return http({
    url: '/admin/merchant/delete',
    method: 'post',
    data: { id }
  })
}
// 新增
export function addMerchantAPI(data) {
  return http({
    url: '/admin/merchant/add',
    method: 'post',
    data
  })
}
// 编辑
export function editMerchantAPI(data) {
  return http({
    url: '/admin/merchant/edit',
    method: 'post',
    data
  })
}
// 详情
export function getMerchantDetailAPI(id) {
  return http({
    url: '/admin/merchant/detail',
    method: 'get',
    params: { id }
  })
}

// 钱包
// 获取验证码
export function getAccpMobileCodeAPI(data) {
  return http({
    url: '/admin/merchant/accp/mobile/apply',
    method: 'post',
    data
  })
}
// 校验验证码
export function checkAccpMobileCodeAPI(data) {
  return http({
    url: '/admin/merchant/accp/mobile/verify',
    method: 'post',
    data
  })
}
// 连连上传
export function accpUploadAPI(data) {
  return http({
    url: '/admin/merchant/accp/file/upload',
    method: 'post',
    data
  })
}
// 开户申请
export function accpAccountApplyAPI(data) {
  return http({
    url: '/admin/merchant/accp/account/apply',
    method: 'post',
    data
  })
}
// 随机密码算法
export function getSecretRandomAPI(merchantId) {
  return http({
    url: '/admin/merchant/accp/secret/random',
    method: 'post',
    data: { merchantId }
  })
}
// 开户验证
export function accpAccountVerifyAPI(data) {
  return http({
    url: '/admin/merchant/accp/account/verify',
    method: 'post',
    data
  })
}
// 钱包详情
export function getAccpDetailAPI(merchantId) {
  return http({
    url: '/admin/merchant/accp/account/detail',
    method: 'get',
    params: { merchantId }
  })
}
// 提现申请
export function handleWithdrawApplyAPI(data) {
  return http({
    url: '/admin/merchant/withdraw/apply',
    method: 'post',
    data
  })
}
// 提现二次验证
export function handleWithdrawVerifyAgainAPI(data) {
  return http({
    url: '/admin/merchant/withdraw/smsVerify',
    method: 'post',
    data
  })
}
