<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="商户名称">
          <el-input v-model="searchData.merchantName" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="法人手机号">
          <el-input v-model="searchData.legalMobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.merchant_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'MerchantAdd' })"
      >新增</el-button
    >
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
    <!-- 修改密码 -->
    <!-- <el-dialog title="修改密码" :visible.sync="dialogVisible" width="40%" :before-close="dialogBeforeClose" :show-close="false">
      <el-form ref="form" :model="formPwd" label-width="80px">
        <el-form-item label="新密码">
          <el-input style="width:300px;" v-model="model" size="small" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="save">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <AccpDialog ref="accp" @getList="getList"></AccpDialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, changeStatusAPI, deleteMerchantAPI } from './api'
import AccpDialog from './accp-dialog.vue'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '商户名称',
    prop: 'merchantName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['merchantName']}</p>
    }
  },
  {
    label: '企业名称',
    prop: 'companyName',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['companyName']}</p>
    }
  },
  {
    label: '法人姓名',
    prop: 'legalName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['legalName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'legalMobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['legalMobile']}</p>
    }
  },
  {
    label: '分配比例',
    prop: 'allocateRate',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['allocateRate']}</p>
    }
  },
  {
    label: '状态',
    prop: 'status',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-switch
            disabled={!this.isShowBtn(this.AUTH_BTN.merchant_list_status)}
            class="switch"
            onChange={() => this.changeStatus(row)}
            v-model={row['status']}
            active-value={1}
            inactive-value={0}
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </div>
      )
    }
  },
  {
    label: '钱包开通',
    prop: 'accpStatus',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['accpStatus'] ? '已开通' : '未开通'}</p>
    }
  },
  {
    label: '操作',
    width: '200',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.merchant_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'MerchantEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.merchant_list_del)}
            style="margin:0 10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row)}
          >
            删除
          </el-link>
          <el-link
            v-show={row['accpStatus']}
            disabled={!this.isShowBtn(this.AUTH_BTN.merchant_list_withdraw)}
            underline={false}
            type="primary"
            onClick={() => this.handleWithdraw(row)}
          >
            提现
          </el-link>
          <el-link
            v-show={!row['accpStatus']}
            disabled={!this.isShowBtn(this.AUTH_BTN.merchant_list_accp)}
            underline={false}
            type="primary"
            onClick={() => this.handleWithdraw(row)}
          >
            钱包
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { AccpDialog },
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      formPwd: {},
      searchData: {
        merchantName: '',
        legalMobile: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { merchantName, legalMobile } = this.searchData
      let params = { merchantName, legalMobile, pageSize: this.pageSize, page: this.currentPage }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteMerchantAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, status } = row
      let data = { id, status: status == 0 ? 0 : 1 }
      let statusDesc = status == 0 ? '禁用' : '启用'

      this.$confirm(`此操作将该商户${statusDesc}吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.status = status == 0 ? 0 : 1
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.status = status == 1 ? 0 : 1
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 钱包提现
    handleWithdraw({ accpStatus, id }) {
      if (!accpStatus) {
        // 未开通--钱包
        this.$store.commit('merchant/SAVE_MERCHANT_ID', id)
        this.$store.commit('merchant/UPDATE_APPLY_NO', '')
        this.$store.commit('merchant/UPDATE_CODE', '')
        this.$refs.accp.show = true
        this.$refs.accp.step = 1
      } else {
        // 已开通--提现
        this.$router.push({ name: 'MerchantAccpDetail', params: { id } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .el-form {
    .el-form-item {
      margin-right: 35px;
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
