<template>
  <!-- <el-form ref="form" :model="formData" label-width="80px">
    <el-form-item label="取现密码">
      <el-input style="width:300px" v-model="formData.password" size="small" clearable></el-input>
      <span class="tip">*支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母</span>
    </el-form-item>
    <el-form-item label="确认密码">
      <el-input style="width:300px" v-model="formData.confirm_password" size="small" clearable></el-input>
      <span class="tip">*支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母</span>
    </el-form-item>
  </el-form> -->

  <div class="wrapper">
    <div class="pwd-wrapper">
      <div id="ipt_pwd2" class="ipt_pwd">
        <span id="LOGPASS2_SHOW" ref="place">请输入密码</span>
        <input id="LOGPASS2" class="default input_search" placeholder="" type="password" ref="pwd" disabled="disabled" />
        <div id="keyboardBtn2" class="ipt_pwd_rjp default"></div>
        <span class="tip">*支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母</span>
      </div>
      <div id="ipt_pwd3" class="ipt_pwd">
        <span id="LOGPASS3_SHOW">请确认密码</span>
        <input id="LOGPASS3" class="default input_search" type="password" disabled="disabled" />
        <div id="keyboardBtn3" class="ipt_pwd_rjp default"></div>
        <span class="tip">*支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母</span>
      </div>
    </div>
    <div class="submit">
      <span></span>
      <el-button :disabled="!checkResult" type="primary" size="small" @click="confirm">确 认</el-button>
    </div>
  </div>
</template>

<script>
import { getSecretRandomAPI, accpAccountVerifyAPI } from '../api'
import { mapState } from 'vuex'
export default {
  name: 'Step3',

  data() {
    return {
      options: {
        // map_arr: '',
        // license:
        //   'UlV2UVVMaThZTU45RXVacnRWR3ptd1ZZUTJoeTFyQm9qbFg4Z0k4Y2R1bFZ1aTVQOUJ0cDQzMU5sUkpNMkF5UFI2c3NJWnAvWTg4UDBIT2c3VVU1dlVtV3pVN2tqMjBiNjFucWlNWS8xS3p6emdrMXFmWUZCdG91cGFKa2tQaFdPaHZlL1FicXdDTVlpRUhxTTVod1lGOHVxOTdUbXd3eXRwaGRRWU9SRFA0PXsiaWQiOjAsInR5cGUiOiJ0ZXN0IiwicGxhdGZvcm0iOjEwLCJub3RiZWZvcmUiOiIyMDIzMDgwNyIsIm5vdGFmdGVyIjoiMjAyNDAyMDcifQ==',
        // oid_partner: '2020042200284052',
        // random_key: '8fb17b28-99f8-4bd7-8476-7c2b852a4dad',
        // random_value: '61626598846675757652590310252891',
        // ret_code: '0000',
        // ret_msg: '交易成功',
        // rsa_public_content: '',
        // sm2_key_hex:
        //   '7032957ba58a4903738ba905fda70c2579cc81e653669015400285d22bdaa84549b52e1f9e4c9533a6c8a86b6838f76401b2c8f14eba926e7dcacca7882c9e74',
        // user_id: ''
      },
      passwordInstance: null,
      passwordInstance2: null,
      pwd: false,
      pwd2: false,
      password1: '',
      password2: '',
      checkResult: false
    }
  },
  computed: {
    ...mapState('merchant', ['merchantId', 'applyNo', 'code'])
  },
  watch: {},
  mounted() {
    // this.getSecretRandom()
  },

  methods: {
    // 随机密码算法
    async getSecretRandom() {
      this.options = await getSecretRandomAPI(this.merchantId)
      this.passwordInstance = this.initPassword(this.options)
      this.passwordInstance2 = this.initPassword2(this.options)
    },
    confirm() {
      const data = {
        applyNo: this.applyNo,
        verifyCode: this.code,
        randomKey: this.options.randomKey,
        password: this.password1
      }
      accpAccountVerifyAPI(data).then(res => {
        if (res) {
          this.$message.success('开户验证成功')
          this.$emit('next')
        } else {
          this.$message.error('开户验证失败')
        }
      })
    },
    initPassword(options = {}) {
      const passowrd = new showKeyBord({
        png: '//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img', // 图片路径，用这个就行了，也可以下载到本地
        click_objId: '#keyboardBtn2', // 触发显示键盘的Dom节点Id
        regExp1: '^[a-zA-Z0-9]+$', // 输入过程限制的正则，不匹配正则的字符不让输入
        regExp2: '^[a-zA-Z0-9]{6,12}$', // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
        Input_texId: '#LOGPASS2', // 接收内容的Dom节点Id
        parent_objId: '#ipt_pwd2', // 将键盘插入到何处的Dom节点Id
        placeholderId: '#LOGPASS2_SHOW', // span标签的id，该span用于模拟Input_texId的placeholder
        len: '12', // 表示最大允许输入多少个字符[可以不传递]
        keyBordLeft: '',
        keyBordTop: '',
        chaosMode: 0, // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
        clickStatus: 0, // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
        titlefont: '安全支付输入',
        backfont: '退 格',
        capsfont: '切换大/小写',
        surefont: '确定',
        isen: false,
        callBack: () => {
          if (this.passwordInstance.pwdSimple() == 1) {
            console.log('简单密码')
            this.pwd = false
            this.$message.error('支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母')
            return
          }
          this.pwd = true
          this.passwordInstance.setRandKey(this.options.randomValue)
          this.password1 = this.passwordInstance.getOutputSM()
          if (this.password2) {
            this.checkResult = this.password1 == this.password2 ? true : false
            if (!this.checkResult) {
              this.$message.error('两次输入密码不一致!')
            }
          }
        }, // 密码校验成功回调
        errorCallBack: () => {
          this.pwd = false
          this.$message.error('支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母')
        }, // 密码校验失败回调
        rsaPublicKey: options.rsa_public_content, // RSA加密必须参数，国密加密可不要
        sm2KeyHex: options.sm2KeyHex, // 国密加密必须参数，RSA加密可不要
        license: options.license // 必须参数
      })
      passowrd.initOptions()
      return passowrd
    },
    initPassword2(options = {}) {
      // eslint-disable-next-line
      const passowrd2 = new showKeyBord({
        png: '//mpay-static.oss-cn-hangzhou.aliyuncs.com/html/lib/state-secret/img', // 图片路径，用这个就行了，也可以下载到本地
        click_objId: '#keyboardBtn3', // 触发显示键盘的Dom节点Id
        regExp1: '^[a-zA-Z0-9]+$', // 输入过程限制的正则，不匹配正则的字符不让输入
        regExp2: '^[a-zA-Z0-9]{6,12}$', // 输入完成后的正则，与getValid()对应，判断输入值是否匹配该正则
        Input_texId: '#LOGPASS3', // 接收内容的Dom节点Id
        parent_objId: '#ipt_pwd3', // 将键盘插入到何处的Dom节点Id
        placeholderId: '#LOGPASS3_SHOW', // span标签的id，该span用于模拟Input_texId的placeholder
        len: '12', // 表示最大允许输入多少个字符[可以不传递]
        keyBordLeft: '',
        keyBordTop: '',
        chaosMode: 0, // 混乱模式,0:无混乱;1:打开时乱一次;2:每输入一个字符乱一次,默认值0
        clickStatus: 0, // 按键状态,0:鼠标划过、悬浮时按键无变化;1:鼠标划过、悬浮时按键的颜色变化,默认值0
        titlefont: '安全支付输入',
        backfont: '退 格',
        capsfont: '切换大/小写',
        surefont: '确定',
        isen: false,
        callBack: () => {
          if (this.passwordInstance2.pwdSimple() == 1) {
            this.pwd2 = false
            this.$message.error('支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母')
            return
          }
          // 成功
          this.pwd2 = true
          this.passwordInstance2.setRandKey(this.options.randomValue)
          this.password2 = this.passwordInstance2.getOutputSM()
          this.checkResult = this.password1 == this.password2 ? true : false
          if (!this.checkResult) {
            this.$message.error('两次输入密码不一致!')
          }
        }, // 密码校验成功回调
        errorCallBack: () => {
          this.pwd2 = false
          this.$message.error('支付密码支持6-12位的字母、数字，不可以是连续或重复的数字和字母')
        }, // 密码校验失败回调
        rsaPublicKey: options.rsa_public_content, // RSA加密必须参数，国密加密可不要
        sm2KeyHex: options.sm2KeyHex, // 国密加密必须参数，RSA加密可不要
        license: options.license // 必须参数
      })
      passowrd2.initOptions()
      return passowrd2
    },
    checkPwd() {
      this.passwordInstance.setRandKey(this.options.randomValue) // 先设置随机key
      this.passwordInstance2.setRandKey(this.options.random_value) // 先设置随机key
      const password1 = this.passwordInstance.getOutputSM() // 国密加密密码
      const password2 = this.passwordInstance2.getOutputSM() // 国密加密密码
      // console.log(this.passwordInstance.pwdSimple())
      // console.log(this.passwordInstance.getLength())
      // console.log(this.passwordInstance.getValid())
      // console.log(this.passwordInstance)
    },
    init() {
      this.passwordInstance.clearPWD()
      this.passwordInstance = null
      this.passwordInstance2 = null
      this.pwd = false
      this.pwd2 = false
      this.password1 = ''
      this.password2 = ''
      this.checkResult = false
      this.$refs.place.style.display = 'block'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .pwd-wrapper /deep/ {
    display: flex;
    flex-direction: column;
    .ipt_pwd {
      position: relative;
      width: 245px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 20px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      #LOGPASS2_SHOW,
      #LOGPASS3_SHOW {
        color: #606266;
        padding-left: 5px;
      }
      .tip {
        position: absolute;
        right: -400px;
        top: 0;
        font-size: 12px;
        color: #8c939d;
        margin-left: 20px;
      }
    }
    .key_main_buttonL {
      .row2 {
        width: 515px !important;
      }
    }
    #keyboardBtn2,#keyboardBtn3 {
      width: 245px;
    }
  }
  .submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 120px;
    margin-right: 20px;
  }
}
</style>
