<template>
  <div class="wrapper">
    <el-dialog title="开户申请" :visible.sync="show" width="60%" @close="close">
      <!-- 第一步 -->
      <Step1 v-if="step == 1" ref="step1" @next="next"></Step1>
      <!-- 第二步 -->
      <Step2 v-if="step == 2" ref="step2" @next="next"></Step2>
      <!-- 第三步 -->
      <Step3 v-show="step == 3" ref="step3" @next="next"></Step3>
      <!-- <div slot="footer">
      <el-button :disabled="isDisabled" type="primary" @click="next" size="small">
        {{ step == 3 ? '确 认' : '下一步' }}
      </el-button>
    </div> -->
    </el-dialog>
  </div>
</template>

<script>
import Step1 from './modules/step1.vue'
import Step2 from './modules/step2.vue'
import Step3 from './modules/step3.vue'
export default {
  name: 'Dialog',
  components: { Step1, Step2, Step3 },
  data() {
    return {
      show: false,
      step: 1
    }
  },
  computed: {},
  mounted() {},
  watch: {
    step: {
      handler(newV, oldV) {
        if (this.step == 3) {
          this.$refs.step3.getSecretRandom()
        }
      }
    }
  },
  methods: {
    next() {
      if (this.step == 1) {
        this.step++
      } else if (this.step == 2) {
        this.step++
      } else if (this.step == 3) {
        this.show = false
        this.$emit('getList')
      }
    },
    close() {
      if (this.step == 1) {
        this.$refs.step1.init()
      } else if (this.step == 2) {
        this.$refs.step2.init()
      } else if (this.step == 3) {
        this.$refs.step3.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  .el-dialog {
    min-height: 400px;
    .el-dialog__body {
      padding-bottom: 10px;
    }
  }
}
</style>
